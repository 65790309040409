/*@import 'styles/css/font-awesome.min.css';
@import 'styles/css/materialize.css';
@import 'styles/css/style.css';
@import 'styles/css/bootstrap.css';
@import 'styles/css/responsive.css';*/
/*@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';*/


::-webkit-scrollbar {
  width: 8px;
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(153, 144, 163); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #85c227; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #831c6d; 
}

.bg-green {
	background: #85c227;
	color: #ffffff;
}
.bg-success {
  background-color: #85c227!important;
	color: #ffffff;
}
.bg-blue {
  background: #007bff;
  color: #ffffff;
}
.bg-indigo {
  background: #6610f2;
  color: #ffffff;
}
.bg-magenta {
	background: #831c6d;
	color: #ffffff;
}
.btn-green {
	background: #85c227;
	color: #ffffff;
}
.btn-green:hover {
	color: #ffffff;
}
.btn-magenta {
	background: #831c6d;
	color: #ffffff;
}
.btn-magenta:hover {
	background: #440637;
}
.text-green {
	color: #85c227 !important;
}
.text-link {
	color: #85c227 !important;
}
.text-link:hover {
	color: #831c6d !important;
}
.text-magenta {
	color: #831c6d !important;
}
.bg-ash {
  background-color: #f2f2f2 !important;
}
.preloader{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url(assets/images/Preloader_7.gif);
  background-repeat: no-repeat;
  background-position: center center;
}
/*.mg-page-title {
  padding-top: 167px;
}*/
.cursor {
	cursor: pointer;
}
.flex {
  display: flex !important;
}
.sb {
  justify-content: space-between !important;
}
.end {
  justify-content: end !important;
}
.w-100 {
  width: 100% !important;
}
.w-130px {
  width: 130px;
}
.wh-150px {
  width: 150px !important;
  height: 150px !important;
}
.w-120px {
	width: 120px;
}
.w-110px {
	width: 110px;
}
.w-80px {
  width: 80px;
}
.w-50px {
  width: 50px;
}
.w-40px {
  width: 40px;
}
.wh-65px {
  width: 65px;
}
.mh-400px {
  max-height: 400px;
  object-fit: contain;
}
.bs-1 {
  border: 1px solid #85c227 !important;
}
.bs-2 {
  border: 1px solid #536066 !important;
}
.card-h:hover {
  background: rgba(86, 191, 108, 0.1);
}
.rt-card {
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgb(68 88 144 / 12%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.rt-card::before {
  content: "";
  position: absolute;
  background: #d9f1f2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}
.rt-card:hover::before {
  background: #85c227;
  top: 0;
  border-radius: 0px;
}
.rt-card:hover {
  color: #ffffff;
}
.items-img {
  height: 220px;
  object-fit: contain;
}
.img-65 {
  height: 65px;
  width: 65px;
  object-fit: contain;
}
.img-75 {
  height: 75px;
  width: 75px;
  object-fit: contain;
}
.img-350x250 {
  width: 350px;
  height: 234px;
  object-fit: cover;
}
.img-100x65 {
  width: 100px;
  height: 65px;
  object-fit: cover;
}

ngb-modal-backdrop {
    z-index: 1050 !important;
}

.pdl-5 {
  padding-left: 5px !important;
}

.pdl-10 {
  padding-left: 10px !important;
}

.pdl-15 {
  padding-left: 15px !important;
}

.pdl-20 {
  padding-left: 20px !important;
}
.pdt-5 {
  padding-top: 5px !important;
}
.pdt-10 {
  padding-top: 10px !important;
}
.pdt-15 {
  padding-top: 15px !important;
}

.pdr-5 {
  padding-right: 5px !important;
}

.pdr-10 {
  padding-right: 10px !important;
}

.pdr-15 {
  padding-right: 15px !important;
}

.pdr-20 {
  padding-right: 20px !important;
}
.pd-0 {
  padding: 0px !important;
}
.pd-5 {
  padding: 5px !important;
}

.pd-10 {
  padding: 10px !important;
}

.pd-15 {
  padding: 15px !important;
}

.pd-20 {
  padding: 20px !important;
}
.pdb-0 {
  padding-bottom: 0px !important;
}
.pdb-5 {
  padding-bottom: 5px !important;
}

.pdb-10 {
  padding-bottom: 10px !important;
}

.pdb-15 {
  padding-bottom: 15px !important;
}

.pdb-20 {
  padding-bottom: 20px !important;
}

.mg-auto {
  margin: auto !important;
}
.mg-5 {
  margin: 5px !important;
}
.mg-10 {
  margin: 10px !important;
}
.mg-15 {
  margin: 15px !important;
}
.mg-20 {
  margin: 20px !important;
}

.mgl-5 {
  margin-left: 5px !important;
}

.mgl-10 {
  margin-left: 10px !important;
}

.mgl-15 {
  margin-left: 15px !important;
}

.mgl-20 {
  margin-left: 20px !important;
}
.mgl-auto {
  margin-left: auto !important;
}

.mgr-0 {
  margin-right: 0px !important;
}

.mgr-5 {
  margin-right: 5px !important;
}

.mgr-10 {
  margin-right: 10px !important;
}

.mgr-15 {
  margin-right: 15px !important;
}

.mgr-20 {
  margin-right: 20px !important;
}

.mgr-auto {
  margin-right: auto !important;
}

.mgt-5 {
  margin-top: 5px !important;
}

.mgt-10 {
  margin-top: 10px !important;
}

.mgt-15 {
  margin-top: 15px !important;
}

.mgt-20 {
  margin-top: 20px !important;
}
.mgt--5 {
  margin-top: -5px !important;
}

.mgt--10 {
  margin-top: -10px !important;
}

.mgt--15 {
  margin-top: -15px !important;
}

.mgt--20 {
  margin-top: -20px !important;
}



.mgb-0 {
  margin-bottom: 0px !important;
}
.mgb-5 {
  margin-bottom: 5px !important;
}

.mgb-10 {
  margin-bottom: 10px !important;
}

.mgb-15 {
  margin-bottom: 15px !important;
}

.mgb-20 {
  margin-bottom: 20px !important;
}

.v-align {
	margin: auto 0 !important;
}

  /*text css fontsize*/
.fs-5{
  font-size: 5px !important;
}
.fs-6{
  font-size: 6px !important;
}
.fs-7{
  font-size: 7px !important;
  /*line-height: 13px !important;*/
}
.fs-8{
  font-size: 8px !important;
  /*line-height: 14px !important;*/
}
.fs-9{
  font-size: 9px !important;
  /*line-height: 15px !important;*/
}
.fs-10{
  font-size: 10px !important;
  /*line-height: 16px !important;*/
}
.fs-11{
  font-size: 11px !important;
  /*line-height: 17px !important;*/
}
.fs-12{
  font-size: 12px !important;
  /*line-height: 18px !important;*/
}
.fs-13{
  font-size: 13px !important;
  /*line-height: 19px !important;*/
}
.fs-14{
  font-size: 14px !important;
  /*line-height: 20px !important;*/
}
.fs-15{
  font-size: 15px !important;
  /*line-height: 21px !important;*/
}
.fs-16{
  font-size: 16px !important;
  /*line-height: 22px !important;*/
}
.fs-17{
  font-size: 17px !important;
  /*line-height: 23px !important;*/
}
.fs-18{
  font-size: 18px !important;
  /*line-height: 24px !important;*/
}
.fs-19{
  font-size: 19px !important;
  /*line-height: 25px !important;*/
}
.fs-20{
  font-size: 20px !important;
  /*line-height: 26px !important;*/
}
.fs-21{
  font-size: 21px !important;
  /*line-height: 27px !important;*/
}
.fs-22{
  font-size: 22px !important;
  /*line-height: 28px !important;*/
}
.fs-23{
  font-size: 23px !important;
  /*line-height: 29px !important;*/
}
.fs-24{
  font-size: 24px !important;
  /*line-height: 30px !important;*/
}
.fs-25{
  font-size: 25px !important;
  /*line-height: 31px !important;*/
}
.fs-26{
  font-size: 26px !important;
  /*line-height: 32px !important;*/
}
.fs-27{
  font-size: 27px !important;
  /*line-height: 33px !important;*/
}
.fs-28{
  font-size: 28px !important;
  /*line-height: 34px !important;*/
}
.fs-29{
  font-size: 29px !important;
  /*line-height: 35px !important;*/
}
.fs-30{
  font-size: 30px !important;
  /*line-height: 36px !important;*/
}
.fs-32{
  font-size: 32px !important;
  /*line-height: 38px !important;*/
}
.fs-34{
  font-size: 34px !important;
  /*line-height: 40px !important;*/
}
.fs-36{
  font-size: 36px !important;
  /*line-height: 42px !important;*/
}
.fs-38{
  font-size: 38px !important;
  /*line-height: 44px !important;*/
}
.fs-40{
  font-size: 40px !important;
  /*line-height: 46px !important;*/
}
.fs-42{
  font-size: 42px !important;
  /*line-height: 48px !important;*/
}
.fs-44{
  font-size: 44px !important;
}
.fs-46{
  font-size: 46px !important;
  /*line-height: 52px !important;*/
}
.fs-48{
  font-size: 48px !important;
  /*line-height: 54px !important;*/
}
.fs-50{
  font-size: 50px !important;
  /*line-height: 56px !important;*/
}

.fw-500{
  font-weight: 500 !important;
}
.fw-600{
  font-weight: 600 !important;
}
.fw-700{
  font-weight: 700 !important;
}



.bdra-4{
  border-radius: 4px !important;
}
.bdra-5{
  border-radius: 5px !important;
  overflow: hidden;
}
.bdra-6{
  border-radius: 6px;
  overflow: hidden;
}
.bdra-7{
  border-radius: 7px;
  overflow: hidden;
}
.bdra-8{
  border-radius: 8px;
  overflow: hidden;
}
.bdra-9{
  border-radius: 9px;
  overflow: hidden;
}
.bdra-10{
  border-radius: 10px;
  overflow: hidden;
}
.bdra-12{
  border-radius: 12px;
  overflow: hidden;
}
.bdra-15{
  border-radius: 15px;
  overflow: hidden;
}
.bdra-20{
  border-radius: 20px;
  overflow: hidden;
}
.circle{
  border-radius: 50%;
  overflow: hidden;
}
.custom-modalbox mat-dialog-container {
  background-color: #831c6d !important;
}

.header.transp .navbar-inverse, .header.transp .mg-search-box {
  background-color: rgba(255, 255, 255, 1) !important;
}

.navbar-inverse .navbar-nav > li > a {
  color: #000000;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
 
}
.r-ca .owl-nav .owl-next, .r-ca .owl-nav .owl-prev {
  position: absolute;
  top: 40px;
  /*margin: 0;
  transform: translate(-50%, -50%);*/
}
.join-us .dropdown-menu {
    background-color: #831c6d;
}
.join-us .dropdown-menu > li > a {
    color: #ffffff;
    padding: 10px 20px;
    transition: background-color 0.5s;
    display: block;
}
.join-us .dropdown-menu > li:hover {
    background-color: #85c227;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #40c640;
}
.table-hover tbody tr:hover {
  background-color: rgba(64,198,64,.075);
}
/*.mg-page-title {
  padding-top: 70px;
  padding-bottom: 50px;
  background-image: url(assets/images/page-title.png);
  color: #fff;
  text-align: center;
}*/

.my-custom-class .tooltip-inner {
  background-color: #831c6d;
  font-size: 100%;
  font-style: italic;
}
.my-custom-class.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #831c6d;
}
.my-custom-class.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #831c6d;
}
.my-custom-class.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #831c6d;
}
.my-custom-class.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #831c6d;
}
.MsoNormal {
  text-align: justify !important;
}
.custom-dialog > mat-dialog-container > div .mat-mdc-dialog-surface{
  border-radius: 20px;
}

/*.logo-width {
  width: 150px !important;
}

@media (max-width: 991px) {
  .logo-width {
    width: 100px !important;
  }
} 
@media (max-width: 767px) {
  .logo-width {
    width: 100px !important;
  }
}
@media (max-width: 662px) {
  .logo-width {
    width: 100px !important;
  }
}
@media (max-width: 500px) {
  .logo-width {
    width: 100px !important;
  }
}
@media (max-width: 400px) {
  .logo-width {
    width: 100px !important;
  }
}*/

html, body { 
	height: 100%; 
}
body { 
	margin: 0; 
/*	font-family: Roboto, "Helvetica Neue", sans-serif; */
	font-family: Montserrat, sans-serif;
}
