@charset "UTF-8";
body {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  color: #4b565b;
  word-spacing: 3px;
}

sub,
sup {
  font-size: 60%;
}

a {
  color: #85c227;
  text-decoration: none !important;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}

a:hover, a:active, a:focus {
  color: #16262e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
}

h1,
.h1 {
  font-size: 38px;
}

h2,
.h2 {
  font-size: 32px;
}

h3,
.h3 {
  font-size: 26px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
}

p {
/*  margin-bottom: 23px;*/
}

hr {
  margin: 30px 0;
}

blockquote {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-style: italic;
  background-image: url(../../../assets/images/blockquote.png);
/*  background-color: #f7f7f7;*/
  background-repeat: no-repeat;
  background-position: 10px 10px;
  border-left-width: 0;
  padding: 15px;
  padding-left: 50px;
  border-radius: 1px;
}

input[type=radio],
input[type=checkbox] {
  border: 1px solid #ced4d7;
  background: #fff;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 20px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  min-width: 20px;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  outline-width: 0;
  -webkit-transition: 0.05s border-color ease-in-out;
  transition: 0.05s border-color ease-in-out;
}

input[type=radio]:checked,
input[type=checkbox]:checked {
  border-color: #85c227;
}

input[type=radio]:checked:before,
input[type=checkbox]:checked:before {
  display: block;
  vertical-align: middle;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=checkbox]:checked:before {
  content: '\f00c';
  color: #85c227;
  margin-top: 9px;
  font-size: 11px;
}

input[type=radio] {
  border-radius: 50%;
}

input[type=radio]:checked:before {
  content: '\f111';
  color: #85c227;
  margin-top: 9px;
  font-size: 8px;
}

img {
  max-width: 100%;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.navbar {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

.mg-navs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar-default {
  background-color: #fff;
  border-width: 0;
  border-radius: 0;
}

.navbar-inverse {
  background-color: #16262e;
  color: #fff;
  border-width: 0;
  border-radius: 0;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.navbar-inverse .navbar-nav > li > a {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: color 0.3s, padding 0.3s;
  transition: color 0.3s, padding 0.3s;
}

.navbar-inverse .navbar-nav > li > a:hover {
  color: #85c227;
}

.navbar-inverse .navbar-nav > li.active > a {
  background-color: transparent;
  -webkit-box-shadow: inset 0 -3px #85c227;
          box-shadow: inset 0 -3px #85c227;
  color: #85c227;
}

.navbar-inverse .navbar-nav > li.active > a:hover, .navbar-inverse .navbar-nav > li.active > a:focus, .navbar-inverse .navbar-nav > li.active > a:active {
  color: #85c227;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > li.show > a {
  background-color: transparent;
  color: #85c227;
}

.navbar-inverse .navbar-nav > li.show > a:hover, .navbar-inverse .navbar-nav > li.show > a:focus, .navbar-inverse .navbar-nav > li.show > a:active {
  color: #85c227;
  background-color: transparent;
}

.navbar-inverse .navbar-nav > li ul.dropdown-menu {
  background-color: #ffffff;
  border-radius: 0;
  padding: 0;
  margin-top: -1px;
  min-width: 200px;
  right: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-width: 0;
}

.navbar-inverse .navbar-nav > li ul.dropdown-menu > li > a {
  color: #000000;
  padding: 10px 20px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.navbar-inverse .navbar-nav > li ul.dropdown-menu > li > a:hover {
  color: #ffffff;
  background-color: #85c227;
}

.navbar-inverse .navbar-nav > li ul.dropdown-menu > li.active > a {
  background-color: #85c227;
}

.navbar-nav li {
  display: block;
}

.navbar-nav li a {
  display: block;
}

.navbar-nav > li > a {
  font-size: 16px;
  line-height: 20px;
  padding-top: 39px;
  padding-bottom: 38px;
  padding-left: 20px;
  padding-right: 20px;
}

.dropdown-menu {
  font-size: 14px;
}

.dropdown > ul {
  display: block;
  visibility: hidden;
  top: 110%;
  opacity: 0;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.dropdown > ul > .dropdown > ul {
  left: 100%;
  top: 0;
}

.dropdown.show > ul {
  visibility: visible;
  top: 100%;
  opacity: 1;
}

.navbar-toggler {
  border-radius: 1px;
  border-color: #831c6d !important;
  margin-top: 20px;
/*  margin-right: 110px;*/
  margin-bottom: 21px;
  padding: 9px 10px;
  background-color: #831c6d !important;
}

.navbar-toggler:hover,
.navbar-toggler:active,
.navbar-toggler:focus {
  background-color: #85c227 !important;
  border-color: #85c227 !important;
}

.navbar-toggler .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggler .icon-bar {
  background-color: #fff;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.btn {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  border-radius: 1px;
  font-weight: 600;
  border-width: 0;
  padding: 8px 10px;
  -webkit-transition: color 0.5s, border-color 0.5s, background-color 0.5s;
  transition: color 0.5s, border-color 0.5s, background-color 0.5s;
}

.btn-dark {
  color: #FFF;
  background-color: #831c6d;
  border-color: #831c6d;
}

.btn-dark:hover {
  background-color: #85c227;
  border-color: #85c227;
  color: #fff;
}

.btn-outline-dark {
  border-width: 1px;
  border-style: solid;
  border-color: #16262e;
  color: #16262e;
}

.btn-outline-dark:hover {
  background-color: #16262e;
  border-color: #16262e;
  color: #fff;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-outline-default {
  border-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
  color: #333;
}

.btn-outline-default:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.btn-main {
  color: #FFF;
  background-color: #85c227;
  border-color: #85c227;
}

.btn-main:hover {
  background-color: #440637;
  border-color: #440637;
  color: #fff;
}

.btn-outline-main {
  border-width: 1px;
  border-style: solid;
  border-color: #eec341;
  color: #eec341;
}

.btn-outline-main:hover {
  background-color: #eec341;
  border-color: #eec341;
  color: #fff;
}

.btn-dark-main {
  color: #FFF;
  background-color: #16262e;
  border-color: #16262e;
}

.btn-dark-main:hover {
  background-color: #85c227;
  border-color: #85c227;
  color: #fff;
}

.btn-outline-dark-main {
  border-width: 1px;
  border-style: solid;
  border-color: #16262e;
  color: #16262e;
}

.btn-outline-dark-main:hover {
  background-color: #85c227;
  border-color: #85c227;
  color: #fff;
}

.btn-primary {
  color: #FFF;
  background-color: #3b95e8;
  border-color: #3b95e8;
}

.btn-primary:hover {
  background-color: #69aeed;
  border-color: #69aeed;
  color: #fff;
}

.btn-outline-primary {
  border-width: 1px;
  border-style: solid;
  border-color: #3b95e8;
  color: #3b95e8;
}

.btn-outline-primary:hover {
  background-color: #3b95e8;
  border-color: #3b95e8;
  color: #fff;
}

.btn-success {
  color: #FFF;
  background-color: #85c227;
  border-color: #85c227;
}

.btn-success:hover {
  background-color: #85c227;
  border-color: #85c227;
  color: #fff;
}

.btn-outline-success {
  border-width: 1px;
  border-style: solid;
  border-color: #85c227;
  color: #85c227;
}

.btn-outline-success:hover {
  background-color: #85c227;
  border-color: #85c227;
  color: #fff;
}

.btn-warning {
  color: #FFF;
  background-color: #f6a430;
  border-color: #f6a430;
}

.btn-warning:hover {
  background-color: #f8b961;
  border-color: #f8b961;
  color: #fff;
}

.btn-outline-warning {
  border-width: 1px;
  border-style: solid;
  border-color: #f6a430;
  color: #f6a430;
}

.btn-outline-warning:hover {
  background-color: #f6a430;
  border-color: #f6a430;
  color: #fff;
}

.btn-info {
  color: #FFF;
  background-color: #2ebfea;
  border-color: #2ebfea;
}

.btn-info:hover {
  background-color: #5ccdef;
  border-color: #5ccdef;
  color: #fff;
}

.btn-outline-info {
  border-width: 1px;
  border-style: solid;
  border-color: #2ebfea;
  color: #2ebfea;
}

.btn-outline-info:hover {
  background-color: #2ebfea;
  border-color: #2ebfea;
  color: #fff;
}

.btn-danger {
  color: #FFF;
  background-color: #f23a34;
  border-color: #f23a34;
}

.btn-danger:hover {
  background-color: #f56964;
  border-color: #f56964;
  color: #fff;
}

.btn-outline-danger {
  border-width: 1px;
  border-style: solid;
  border-color: #f23a34;
  color: #f23a34;
}

.btn-outline-danger:hover {
  background-color: #f23a34;
  border-color: #f23a34;
  color: #fff;
}

.btn-link {
  color: #85c227;
}

.progress {
  border-radius: 1px;
  height: 8px;
  overflow: visible;
  margin-top: 45px;
}

.progress + .progress {
  margin-top: 60px;
}

.progress .progress-bar {
  position: relative;
  background-color: #85c227;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.progress .progress-bar .mg-progress-bar-popup {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  position: absolute;
  top: -35px;
  right: 0;
}

.progress .progress-bar .mg-progress-bar-left {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  position: absolute;
  top: -35px;
  left: 0;
}

.progress .progress-bar.bg-dark {
  background-color: #16262e !important;
}

.progress .progress-bar.bg-primary {
  background-color: #3b95e8 !important;
}

.progress .progress-bar.bg-success {
  background-color: #85c227 !important;
}

.progress .progress-bar.bg-info {
  background-color: #2ebfea !important;
}

.progress .progress-bar.bg-warning {
  background-color: #f6a430 !important;
}

.progress .progress-bar.bg-danger {
  background-color: #f23a34 !important;
}

.progress.progress-outline {
  height: 11px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #eaedee;
  background-color: transparent;
  padding: 3px;
}

.mg-tab-top-nav,
.mg-tab-bottom-nav,
.mg-tab-left-nav,
.mg-tab-right-nav {
  margin-bottom: 30px;
}

.nav-tabs,
.nav-tabs.nav-justified {
  border-bottom-width: 0;
  margin-bottom: 0;
  position: relative;
  bottom: 0;
}

.nav-tabs > li,
.nav-tabs.nav-justified > li {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.nav-tabs .nav-link {
  border-radius: 0;
  border-width: 1px 1px 1px 0;
  border-color: #eaedee;
  border-style: solid;
}

.nav-tabs > li > a,
.nav-tabs.nav-justified > li > a {
  border-width: 1px 1px 1px 0;
  border-color: #eaedee;
  border-style: solid;
  background-color: #fff;
  color: #16262e;
  outline-width: 0 !important;
  border-radius: 0;
  padding: 10px 20px;
  margin-right: 0;
  -webkit-transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

.nav-tabs > li > a:hover,
.nav-tabs.nav-justified > li > a:hover {
  background-color: #85c227;
  color: #fff;
  border-color: #85c227;
}

.nav-tabs > li > a.active,
.nav-tabs.nav-justified > li > a.active {
  border-left-width: 0;
  border-color: #eaedee;
  -webkit-box-shadow: inset 0 1px #85c227;
          box-shadow: inset 0 1px #85c227;
  border-bottom-color: transparent;
  border-top-color: #85c227;
  color: #85c227;
}

.nav-tabs > li > a.active:hover,
.nav-tabs.nav-justified > li > a.active:hover,
.nav-tabs > li > a.active:focus,
.nav-tabs.nav-justified > li > a.active:focus,
.nav-tabs > li > a.active:active,
.nav-tabs.nav-justified > li > a.active:active {
  border-left-width: 0;
  border-color: #eaedee;
  border-bottom-color: transparent;
  border-top-color: #85c227;
  color: #85c227;
  background-color: #fff;
}

.nav-tabs > li:first-child > a,
.nav-tabs.nav-justified > li:first-child > a {
  border-left-width: 1px !important;
}

.nav-tabs.nav-justified {
  bottom: -1px;
}

.tab-content {
  border: 1px solid #eaedee;
  padding: 20px;
  margin-bottom: 0;
}

.mg-tab-bottom-nav .nav-tabs,
.mg-tab-bottom-nav .nav-tabs.nav-justified {
  bottom: auto;
  top: -1px;
}

.mg-tab-bottom-nav .nav-tabs > li > a.active,
.mg-tab-bottom-nav .nav-tabs.nav-justified > li > a.active {
  border-left-width: 0;
  border-color: #eaedee;
  -webkit-box-shadow: inset 0 -1px #85c227;
          box-shadow: inset 0 -1px #85c227;
  border-bottom-color: #85c227;
  border-top-color: transparent;
  color: #85c227;
}

.mg-tab-bottom-nav .nav-tabs > li > a.active:hover,
.mg-tab-bottom-nav .nav-tabs.nav-justified > li > a.active:hover,
.mg-tab-bottom-nav .nav-tabs > li > a.active:focus,
.mg-tab-bottom-nav .nav-tabs.nav-justified > li > a.active:focus,
.mg-tab-bottom-nav .nav-tabs > li > a.active:active,
.mg-tab-bottom-nav .nav-tabs.nav-justified > li > a.active:active {
  border-left-width: 0;
  border-color: #eaedee;
  border-bottom-color: #85c227;
  border-top-color: transparent;
  color: #85c227;
  background-color: #fff;
}

.mg-tab-left-nav:before,
.mg-tab-left-nav:after {
  content: " ";
  display: table;
}

.mg-tab-left-nav:after {
  clear: both;
}

.mg-tab-left-nav .nav-tabs,
.mg-tab-left-nav .nav-tabs.nav-justified {
  position: static;
  max-width: 200px;
  width: 200px;
  float: left;
}

.mg-tab-left-nav .nav-tabs > li,
.mg-tab-left-nav .nav-tabs.nav-justified > li {
  width: 100%;
  display: block;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.mg-tab-left-nav .nav-tabs > li > a,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a {
  text-align: left;
  border-width: 1px 1px 0 1px;
  border-color: #eaedee;
  border-style: solid;
}

.mg-tab-left-nav .nav-tabs > li > a:hover,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a:hover {
  background-color: #85c227;
  color: #fff;
  border-color: #85c227;
}

.mg-tab-left-nav .nav-tabs > li > a.active,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a.active {
  border-bottom-width: 0;
  border-color: #eaedee;
  -webkit-box-shadow: inset 1px 0 #85c227;
          box-shadow: inset 1px 0 #85c227;
  border-left-color: #85c227;
  border-right-color: transparent;
  color: #85c227;
}

.mg-tab-left-nav .nav-tabs > li > a.active:hover,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a.active:hover,
.mg-tab-left-nav .nav-tabs > li > a.active:focus,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a.active:focus,
.mg-tab-left-nav .nav-tabs > li > a.active:active,
.mg-tab-left-nav .nav-tabs.nav-justified > li > a.active:active {
  border-left-width: 1px;
  border-bottom-width: 0;
  border-color: #eaedee;
  border-left-color: #85c227;
  border-right-color: transparent;
  -webkit-box-shadow: inset 1px 0 #85c227;
          box-shadow: inset 1px 0 #85c227;
  color: #85c227;
  background-color: #fff;
}

.mg-tab-left-nav .nav-tabs > li:last-child > a,
.mg-tab-left-nav .nav-tabs.nav-justified > li:last-child > a {
  border-bottom-width: 1px !important;
}

.mg-tab-left-nav .tab-content {
  margin-left: 199px;
}

.mg-tab-right-nav:before,
.mg-tab-right-nav:after {
  content: " ";
  display: table;
}

.mg-tab-right-nav:after {
  clear: both;
}

.mg-tab-right-nav .nav-tabs,
.mg-tab-right-nav .nav-tabs.nav-justified {
  position: static;
  max-width: 200px;
  width: 200px;
  float: right;
}

.mg-tab-right-nav .nav-tabs > li,
.mg-tab-right-nav .nav-tabs.nav-justified > li {
  width: 100%;
  display: block;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.mg-tab-right-nav .nav-tabs > li > a,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a {
  text-align: left;
  border-width: 1px 1px 0 1px;
  border-color: #eaedee;
  border-style: solid;
}

.mg-tab-right-nav .nav-tabs > li > a:hover,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a:hover {
  background-color: #85c227;
  color: #fff;
  border-color: #85c227;
}

.mg-tab-right-nav .nav-tabs > li > a.active,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a.active {
  border-bottom-width: 0;
  border-color: #eaedee;
  -webkit-box-shadow: inset -1px 0 #85c227;
          box-shadow: inset -1px 0 #85c227;
  border-right-color: #85c227;
  border-left-color: transparent;
  color: #85c227;
}

.mg-tab-right-nav .nav-tabs > li > a.active:hover,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a.active:hover,
.mg-tab-right-nav .nav-tabs > li > a.active:focus,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a.active:focus,
.mg-tab-right-nav .nav-tabs > li > a.active:active,
.mg-tab-right-nav .nav-tabs.nav-justified > li > a.active:active {
  border-right-width: 1px;
  border-bottom-width: 0;
  border-color: #eaedee;
  border-right-color: #85c227;
  border-left-color: transparent;
  -webkit-box-shadow: inset -1px 0 #85c227;
          box-shadow: inset -1px 0 #85c227;
  color: #85c227;
  background-color: #fff;
}

.mg-tab-right-nav .nav-tabs > li:last-child > a,
.mg-tab-right-nav .nav-tabs.nav-justified > li:last-child > a {
  border-bottom-width: 1px !important;
}

.mg-tab-right-nav .tab-content {
  margin-right: 199px;
}

.mg-about-tabs {
  padding: 70px 0;
  background-color: #16262e;
  background-image: url(../../../assets/images/about-bg.png);
  background-position: center center;
  color: #fff;
}

.mg-about-tabs .nav-tabs > li > a,
.mg-about-tabs .nav-tabs.nav-justified > li > a {
  color: #fff;
}

.mg-about-tabs .nav-tabs > li > a.active,
.mg-about-tabs .nav-tabs.nav-justified > li > a.active {
  color: #85c227;
}

.mg-about-tabs .nav-tabs > li > a.active:hover,
.mg-about-tabs .nav-tabs.nav-justified > li > a.active:hover,
.mg-about-tabs .nav-tabs > li > a.active:focus,
.mg-about-tabs .nav-tabs.nav-justified > li > a.active:focus,
.mg-about-tabs .nav-tabs > li > a.active:active,
.mg-about-tabs .nav-tabs.nav-justified > li > a.active:active {
  color: #85c227;
}

.alert {
  border-radius: 1px;
  margin-bottom: 23px;
}

.alert .mg-alert-icon {
  font-size: 28px;
  line-height: 56px;
  width: 60px;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-color: #831c6d;
}

.alert .close {
  opacity: 0.5;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.alert .close:hover {
  opacity: 1;
}

.alert-success {
  border-color: #d0edce;
  background-color: #effbf0;
  color: #60a662;
}

.alert-success .mg-alert-icon {
  color: #60a662;
  border-color: #60a662;
}

.alert-success .mg-alert-payment {
  display: inline-block;
  margin-left: 20px;
  font-size: 20px;
}

.alert-success h1,
.alert-success h2,
.alert-success h3,
.alert-success h4,
.alert-success h5,
.alert-success h6 {
  color: #60a662;
  margin-top: 0;
}

.alert-success .close {
  color: #60a662;
}

.alert-info {
  border-color: #bceafc;
  background-color: #e3f5fc;
  color: #53adcf;
}

.alert-info .mg-alert-icon {
  color: #53adcf;
  border-color: #53adcf;
}

.alert-info .mg-alert-payment {
  display: inline-block;
  margin-left: 20px;
  font-size: 20px;
}

.alert-info h1,
.alert-info h2,
.alert-info h3,
.alert-info h4,
.alert-info h5,
.alert-info h6 {
  color: #53adcf;
  margin-top: 0;
}

.alert-info .close {
  color: #53adcf;
}

.alert-warning {
  border-color: #f6e4bd;
  background-color: #fdf8ed;
  color: #d3a33b;
}

.alert-warning .mg-alert-icon {
  color: #d3a33b;
  border-color: #d3a33b;
}

.alert-warning .mg-alert-payment {
  display: inline-block;
  margin-left: 20px;
  font-size: 20px;
}

.alert-warning h1,
.alert-warning h2,
.alert-warning h3,
.alert-warning h4,
.alert-warning h5,
.alert-warning h6 {
  color: #d3a33b;
  margin-top: 0;
}

.alert-warning .close {
  color: #d3a33b;
}

.alert-danger {
  border-color: #faced3;
  background-color: #fceaec;
  color: #e74659;
}

.alert-danger .mg-alert-icon {
  color: #d3a33b;
  border-color: #d3a33b;
}

.alert-danger .mg-alert-payment {
  display: inline-block;
  margin-left: 20px;
  font-size: 20px;
}

.alert-danger h1,
.alert-danger h2,
.alert-danger h3,
.alert-danger h4,
.alert-danger h5,
.alert-danger h6 {
  color: #d3a33b;
  margin-top: 0;
}

.alert-danger .close {
  color: #d3a33b;
}

.alert-primary {
  color: #1466d6;
  background-color: #ecf4ff;
  border-color: #cfe1f9;
}

.alert-primary .mg-alert-icon {
  color: #1466d6;
  border-color: #1466d6;
}

.alert-primary h1, .alert-primary h2, .alert-primary h3, .alert-primary h4, .alert-primary h5, .alert-primary h6 {
  color: #1466d6;
  margin-top: 0;
}

.alert-primary .close {
  color: #1466d6;
}

.alert-secondary {
  color: #4b5e71;
  background-color: #ebeff3;
  border-color: #d2dce6;
}

.alert-secondary .mg-alert-icon {
  color: #4b5e71;
  border-color: #4b5e71;
}

.alert-secondary h1, .alert-secondary h2, .alert-secondary h3, .alert-secondary h4, .alert-secondary h5, .alert-secondary h6 {
  color: #4b5e71;
  margin-top: 0;
}

.alert-secondary .close {
  color: #4b5e71;
}

.alert-light {
  color: #7d8a90;
  background-color: #f8fafb;
  border-color: #f1f5f7;
}

.alert-light .mg-alert-icon {
  color: #7d8a90;
  border-color: #7d8a90;
}

.alert-light h1, .alert-light h2, .alert-light h3, .alert-light h4, .alert-light h5, .alert-light h6 {
  color: #7d8a90;
  margin-top: 0;
}

.alert-light .close {
  color: #7d8a90;
}

.alert-dark {
  color: #ffffff;
  background-color: #3b4a52;
  border-color: #3b4a52;
}

.alert-dark .mg-alert-icon {
  color: #ffffff;
  border-color: #ffffff;
}

.alert-dark h1, .alert-dark h2, .alert-dark h3, .alert-dark h4, .alert-dark h5, .alert-dark h6 {
  color: #ffffff;
  margin-top: 0;
}

.alert-dark .close {
  color: #ffffff;
}

.mg-boxed .mg-page {
  position: relative;
  z-index: 1;
}

.mg-boxed .mg-single-room,
.mg-boxed .mg-single-room-review-sec,
.mg-boxed .mg-blog-list,
.mg-boxed .mg-page,
.mg-boxed .mg-team,
.mg-boxed .mg-about-features {
  background-color: #fff;
}

.mt50 {
  margin-top: 50px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mg-404-error-txt {
  padding: 0 190px;
}

.mg-404-error-txt .mg-404-badg {
  background-color: #16262e;
  color: #fff;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 30px 28px;
  border-radius: 100px;
  margin-right: 50px;
}

.mg-404-error-txt .mg-404-badg strong {
  display: block;
  font-size: 80px;
  line-height: 90px;
  vertical-align: top;
  margin: -30px 0 30px;
  position: relative;
}

.mg-404-error-txt .mg-404-badg strong:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -40px;
  background-color: #85c227;
}

.mg-404-error-txt .mg-404-badg span {
  font-size: 30px;
  line-height: 40px;
}

.mg-404-error-txt .mg-404-txt-search {
  text-align: left;
}

.mg-404-error-txt .mg-404-txt-search strong {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 23px;
  line-height: 33px;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
}

.mg-404-error-txt .mg-404-txt-search p {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form input {
  max-width: 250px;
  display: inline-block;
  float: left;
  border-radius: 1px 0 0 1px;
  border-right-width: 0;
  background-color: transparent;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form input::-webkit-input-placeholder {
  color: #fff;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form input:-ms-input-placeholder {
  color: #fff;
}

.mg-404-error-txt .mg-404-txt-search .mg-404-search-form button {
  display: inline-block;
  float: left;
  border-radius: 0 1px 1px 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

.custom-select,
.form-control {
  font-size: 14px;
  border-radius: 1px;
  margin-bottom: 20px;
  border-color: #ced4d7;
  padding: 8px 12px;
  height: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #4b565b;
}

.form-control::-webkit-input-placeholder,
.form-control:-moz-placeholder,
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder {
  color: #ced4d7;
}

.custom-select:focus,
.form-control:focus {
  border-color: #85c227;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mg-boxed {
  width: 1200px;
  background-color: #eef3fa;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: auto;
  background-image: url(../../../assets/images/paisley.png);
  background-attachment: fixed;
  position: relative;
}

.mg-boxed .header {
  max-width: 1200px;
}

.mg-boxed .carousel-caption > h2 {
  font-size: 57px;
}

.mg-boxed .carousel-caption > p {
  font-size: 18px;
  line-height: 28px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.parallax {
  background-attachment: fixed !important;
}

.mb250 {
  margin-bottom: 250px !important;
}

.mb245 {
  margin-bottom: 245px !important;
}

.mb240 {
  margin-bottom: 240px !important;
}

.mb235 {
  margin-bottom: 235px !important;
}

.mb230 {
  margin-bottom: 230px !important;
}

.mb225 {
  margin-bottom: 225px !important;
}

.mb220 {
  margin-bottom: 220px !important;
}

.mb215 {
  margin-bottom: 215px !important;
}

.mb210 {
  margin-bottom: 210px !important;
}

.mb205 {
  margin-bottom: 205px !important;
}

.mb200 {
  margin-bottom: 200px !important;
}

.mb195 {
  margin-bottom: 195px !important;
}

.mb190 {
  margin-bottom: 190px !important;
}

.mb185 {
  margin-bottom: 185px !important;
}

.mb180 {
  margin-bottom: 180px !important;
}

.mb175 {
  margin-bottom: 175px !important;
}

.mb170 {
  margin-bottom: 170px !important;
}

.mb165 {
  margin-bottom: 165px !important;
}

.mb160 {
  margin-bottom: 160px !important;
}

.mb155 {
  margin-bottom: 155px !important;
}

.mb150 {
  margin-bottom: 150px !important;
}

.mb145 {
  margin-bottom: 145px !important;
}

.mb140 {
  margin-bottom: 140px !important;
}

.mb135 {
  margin-bottom: 135px !important;
}

.mb130 {
  margin-bottom: 130px !important;
}

.mb125 {
  margin-bottom: 125px !important;
}

.mb120 {
  margin-bottom: 120px !important;
}

.mb115 {
  margin-bottom: 115px !important;
}

.mb110 {
  margin-bottom: 110px !important;
}

.mb105 {
  margin-bottom: 105px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

ul.styled-list li {
  list-style: none;
  padding-left: 0;
}

ul.styled-list li:before {
  content: '\f00c';
  font-family: 'FontAwesome';
  display: inline-block;
  margin-left: -1.5em;
  width: 1.5em;
  color: #16262e;
}

ul.styled-list.list-caret li:before {
  content: '\f0da';
}

ul.styled-list.list-hand li:before {
  content: '\f0a4';
}

ul.styled-list.list-chevron li:before {
  content: '\f054';
}

ul.styled-list.list-times li:before {
  content: '\f00d';
}

ul.styled-list.list-star li:before {
  content: '\f006';
}

.header {
  width: 100%;
}

.header.transp {
  position: absolute;
  top: 0;
  z-index: 999;
}

.header.transp .navbar-inverse,
.header.transp .mg-search-box {
  background-color: rgba(22, 38, 46, 0.3);
}

.header.transp .navbar-default {
  background-color: rgba(255, 255, 255, 0.3);
}

.header .navbar-default,
.header .navbar-inverse {
  text-align: right;
}

.header.center-content {
  text-align: center;
}

.header.center-content .navbar-header {
  float: none;
}

.header.center-content .navbar-header .navbar-brand {
  float: none;
  display: inline-block;
}

.header.center-content .navbar-right {
  float: none !important;
}

.header.center-content .container {
  width: 100%;
  padding: 0;
}

.header.center-content .navbar-collapse {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.header.center-content .navbar-default .navbar-collapse {
  border-color: rgba(0, 0, 0, 0.05);
}

.header.center-content .navbar-nav {
  float: none;
  display: inline-block;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.header.center-content .navbar-nav > li > a {
  padding: 20px 25px;
}

.header.sticky-on {
  position: fixed;
  top: 0;
  z-index: 999;
}

.header.sticky-on .mg-search-box {
  background-color: rgba(22, 38, 46, 0.95);
}

.header.sticky-on .navbar-inverse {
  background-color: rgba(22, 38, 46, 0.95);
}

.header.sticky-on .navbar-inverse .navbar-nav > li > a {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header.sticky-on .navbar-brand > img {
  height: 50px;
}

.header.sticky-on.center-content .navbar-header .navbar-brand {
  display: none;
}

.header.sticky-on .mg-search-box-cont {
  margin: 12px 20px 13px;
}

.header.sticky-on .mg-search-box {
  bottom: -71px;
}

.navbar-brand {
  float: left;
  height: auto;
  padding: 10px 0;
  font-size: 18px;
  line-height: 20px;
  margin-left: 0 !important;
}

.navbar-brand img {
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}

.mg-search-box-cont {
  margin: 26px 20px;
  position: relative;
  -webkit-transition: margin 0.3s;
  transition: margin 0.3s;
}

.mg-search-box-cont:after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  background-color: #85c227;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: -14px;
  margin-top: -2px;
}

.mg-search-box-trigger {
  padding: 11px 0;
  text-align: center;
  width: 43px;
  cursor: pointer;
  display: block;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.mg-search-box-trigger:hover,
.mg-search-box-trigger:active,
.mg-search-box-trigger:focus {
  color: #fff;
}

.mg-search-box-trigger.mg-sb-active {
  color: #85c227;
}

.mg-search-box {
  min-width: 300px;
  position: absolute;
  bottom: -85px;
  right: 0;
  padding: 10px;
  background-color: rgba(22, 38, 46, 0.95);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform-origin: top;
  transform-origin: top;
}

.mg-search-box.mg-sb-active {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.mg-search-box input {
  display: inline-block;
  width: 80%;
  float: left;
  border-radius: 1px 0 0 1px;
  margin-bottom: 0;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-right-width: 0;
}

.mg-search-box input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.5;
}

.mg-search-box input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  opacity: 0.5;
}

.mg-search-box input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  opacity: 0.5;
}

.mg-search-box input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.5;
}

.mg-search-box button {
  padding: 9px 25px;
  width: 20%;
  float: left;
  border-radius: 0 1px 1px 0;
  text-indent: -2px;
}

.mg-page-title {
  padding-top: 167px;
}

.mg-page-title {
/*  padding-top: 70px;*/
  padding-bottom: 50px;
/*  background-image: url(../../../assets/images/page-title.png);*/
  background-image: url(../../../assets/images/page-t.jpg);
  color: #fff;
  text-align: center;
  background-position: center;
}

.mg-page-title .mg-page {
  background-color: transparent;
}

.mg-page-title h2 {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 35px;
  margin-top: 0;
  font-size: 45px;
}

.mg-page-title h2:after {
  content: '';
  width: 110px;
  height: 3px;
  background-color: #85c227;
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -55px;
}

.header.transp + .mg-page-title {
  padding-top: 167px;
}

.header.transp.center-content + .mg-page-title {
  padding-top: 235px;
}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-caption {
  right: 9%;
  left: 9%;
  text-shadow: none;
  padding: 0;
  bottom: 48%;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.carousel-caption > h2 {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 42px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 15px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.carousel-caption > h2:after {
  content: '';
  display: block;
  width: 120px;
  height: 3px;
  background-color: #85c227;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -60px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.carousel-caption > img {
  opacity: 0;
  margin-top: -20px;
  -webkit-transition: opacity 0.5s, margin-top 0.5s;
  transition: opacity 0.5s, margin-top 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.carousel-caption > p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.header.transp + .carousel .carousel-caption {
  margin-bottom: -40px;
}

.header.transp.center-content + .carousel .carousel-caption {
  margin-bottom: -60px;
}

.carousel-inner .carousel-item.active .carousel-caption > h2 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.carousel-inner .carousel-item.active .carousel-caption > h2:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.carousel-inner .carousel-item.active .carousel-caption > p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.carousel-inner .carousel-item.active .carousel-caption > img {
  opacity: 1;
  margin-top: 0px;
}

/*###
    ### Active at init fixed
    ###*/
.carousel-inner > .beactive {
  display: block;
}

.carousel-control {
  width: 80px;
  height: 120px;
  top: 50%;
  margin-top: -60px;
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url(../../../assets/images/cur-arrow-left.png) !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 33px 60px;
  -webkit-transition: left 0.3s, right 0.3s;
  transition: left 0.3s, right 0.3s;
}

.carousel-control.left {
  left: -100px;
}

.carousel-control.right {
  background-image: url(../../../assets/images/cur-arrow-right.png) !important;
  right: -100px;
}

.header.transp + .carousel .carousel-control {
  margin-top: -10px !important;
}

.header.transp.center-content + .carousel .carousel-control {
  margin-top: 22px !important;
}

.carousel {
  overflow: hidden;
}

.carousel:hover .carousel-control.left {
  left: 0;
}

.carousel:hover .carousel-control.right {
  right: 0;
}

.mg-book-now {
  background-color: #16262e;
  padding: 30px 0;
}

.mg-book-now .mg-bn-title {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #85c227;
  font-size: 22px;
  line-height: 43px;
  text-transform: uppercase;
  margin: 0;
  padding: 20px 0;
  -webkit-box-shadow: 3px 0 #85c227;
          box-shadow: 3px 0 #85c227;
}

.mg-book-now .mg-bn-title .mg-bn-big {
  display: block;
  font-size: 17px;
  line-height: 28px;
  color: #fff;
}

.mg-book-now .focus .form-control {
  color: #fff !important;
  border-color: #85c227 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.mg-book-now .focus .input-group-addon {
  border-color: #85c227 !important;
  color: #fff !important;
  background-color: #85c227 !important;
}

.mg-book-now .mg-bn-forms {
  padding: 36px 0px;
}

.mg-book-now .mg-bn-forms .input-group-addon:first-child {
  border-right: 0;
}

.mg-book-now .mg-bn-forms .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mg-book-now .mg-bn-forms .input-group-addon {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.mg-book-now .mg-bn-forms .input-group-addon:first-child {
  border-radius: 1px 0px 0px 1px;
}

.mg-book-now .mg-bn-forms .input-group .form-control {
  border-radius: 0 1px 1px 0;
}

.mg-book-now .mg-bn-forms .form-control {
  height: auto;
  padding: 8px 12px;
  border-radius: 1px;
  border-color: #fff;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  margin-bottom: 0;
}

.mg-book-now .mg-bn-forms .form-control::-webkit-input-placeholder {
  color: #fff;
}

.mg-book-now .mg-bn-forms .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.mg-book-now .mg-bn-forms .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.mg-book-now .mg-bn-forms .form-control:-ms-input-placeholder {
  color: #fff;
}

.mg-book-now .mg-bn-forms .form-control:focus {
  color: #fff;
  border-color: #831c6d;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mg-book-now .mg-bn-forms .btn {
  padding: 9px 12px;
}

.mg-book-now .mg-bn-forms .col-lg-3,
.mg-book-now .mg-bn-forms .col-md-6,
.mg-book-now .mg-bn-forms .col-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.mg-book-now .mg-bn-forms > form > .row:first-child .row {
  margin-right: -10px;
  margin-left: -10px;
}

.mg-book-now .cs-active > .cs-placeholder {
  border-color: #85c227;
  color: #fff;
}

.mg-book-now.mg-book-now-light {
  background-color: #f2f2f2;
}

.mg-book-now.mg-book-now-light .mg-bn-title .mg-bn-big {
  color: #16262e;
}

.mg-book-now.mg-book-now-light .cs-active > .cs-placeholder {
  border-color: #85c227;
  color: #85c227;
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control {
  border-color: #889195;
  color: #16262e;
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control::-webkit-input-placeholder {
  color: rgba(22, 38, 46, 0.5);
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(22, 38, 46, 0.5);
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(22, 38, 46, 0.5);
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control:-ms-input-placeholder {
  color: rgba(22, 38, 46, 0.5);
}

.mg-book-now.mg-book-now-light .mg-bn-forms .form-control:focus {
  color: #16262e;
  border-color: #85c227;
}

.mg-book-now.mg-book-now-light .mg-bn-forms .input-group-addon {
  color: #16262e;
  border-color: #889195;
}

.mg-book-now.mg-book-now-light .mg-bn-forms div.cs-skin-elastic > span {
  border-color: #889195;
  color: #16262e;
}

.mg-book-now.mg-book-now-light .mg-bn-forms .cs-active > .cs-placeholder {
  border-color: #85c227;
  color: #85c227;
}

.mg-book-now.mg-book-now-light .focus .form-control {
  color: #16262e !important;
  border-color: #85c227 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.mg-book-now.mg-book-now-light .focus .input-group-addon {
  border-color: #85c227 !important;
  color: #fff !important;
  background-color: #85c227 !important;
}

.datepicker {
  padding: 4px;
  border-radius: 1px;
  direction: ltr;
  margin: 0;
}

.datepicker:before,
.datepicker:after {
  border-width: 0;
}

.datepicker table tr td,
.datepicker table tr th {
  color: #4b565b;
  border-radius: 0;
  width: 40px;
  height: 35px;
  text-shadow: none;
}

.datepicker table tr td span {
  height: 54px;
  line-height: 54px;
  border-radius: 1px;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.active:hover,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  color: #FFF;
  background-color: #85c227;
  border-color: #85c227;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  text-shadow: none;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  color: #c0c8cb;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #88979d;
}

.datepicker table tr td.old.disabled,
.datepicker table tr td.new.disabled {
  color: #c0c8cb;
}

div.cs-skin-elastic {
  background: transparent;
  font-size: 14px;
  color: #fff;
}

div.cs-skin-elastic > span {
  background-color: transparent;
  z-index: 100;
  border: 1px solid #fff;
}

div.cs-skin-elastic > span::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f0d7';
  font-weight: 900;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

div.cs-skin-elastic .cs-options {
  border: 1px solid #e1eaee;
  -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
}

div.cs-skin-elastic .cs-options span {
  padding: 4px 12px;
  color: #4b565b;
}

div.cs-skin-elastic .cs-options .cs-selected span {
  color: #85c227;
}

div.cs-skin-elastic .cs-options .cs-selected span:hover {
  color: #fff;
}

div.cs-skin-elastic .cs-options .cs-selected span::after {
  content: '';
}

.cs-skin-elastic .cs-options span:hover,
.cs-skin-elastic .cs-options li.cs-focus span {
  color: #fff;
  background-color: #85c227;
}

.mg-slide-booking {
  position: relative;
}

.mg-slide-booking .mg-book-now {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(22, 38, 46, 0.3);
  padding: 10px 0;
  z-index: 5;
}

.mg-slide-booking .mg-book-now .mg-bn-title {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mg-slide-booking .carousel-caption > h2 {
  font-size: 42px;
}

.mg-slide-booking .carousel-caption > p {
  font-size: 16px;
}

.mg-c2a {
  padding: 50px 0;
  background-color: #16262e;
  color: #fff;
  position: relative;
}

.mg-c2a .col-md-12 {
  position: static;
}

.mg-c2a strong {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 300;
  display: block;
  margin-bottom: 20px;
  margin-left: 200px;
}

.mg-c2a p {
  margin-bottom: 0;
  margin-left: 200px;
}

.mg-c2a .mg-c2a-offer-badg {
  text-align: center;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  float: left;
  width: 150px;
  background-color: #85c227;
  height: 105%;
  padding: 32px 20px;
  position: absolute;
  top: 0;
}

.mg-c2a .mg-c2a-offer-badg:after {
  content: '';
  width: 0;
  height: 0;
  display: block;
  border-width: 30px 75px 0 75px;
  border-color: #85c227 transparent transparent transparent;
  border-style: solid;
  position: absolute;
  bottom: -30px;
  left: 0;
}

.mg-c2a .mg-c2a-offer-badg .mg-c2a-offer-perc {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}

.mg-c2a .btn {
  float: right;
  margin-top: 13px;
}

.mg-c2a .mg-c2a-info {
  float: left;
}

.mg-best-rooms {
  padding: 70px 0 40px;
  background-color: #fff;
}

.mg-sec-title {
  text-align: center;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.mg-sec-title h2 {
  color: #16262e;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 10px;
}

.mg-sec-title p {
  font-size: 16px;
}

.mg-sec-title:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background-color: #85c227;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40px;
}

.mg-sec-title.mg-lighten h2 {
  color: #fff;
}

.mg-sec-title.mg-lighten p {
  color: #fff;
}

.mg-sec-left-title,
.mg-widget-title {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 35px;
  padding-bottom: 15px;
  position: relative;
}

.mg-sec-left-title:after,
.mg-widget-title:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background-color: #85c227;
  position: absolute;
  bottom: 0;
  left: 0;
}

.mg-room {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.mg-room figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.8) 100%);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.mg-room figcaption h2 {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  padding-bottom: 7px;
  margin-bottom: 4px;
  -webkit-transform: translate(0, 120px);
  transform: translate(0, 120px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s;
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.mg-room figcaption h2:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #85c227;
}

.mg-room figcaption .mg-room-fecilities {
  color: #fff;
  margin-bottom: 20px;
}

.mg-room figcaption .mg-room-fecilities ul li {
  color: #fff;
  padding-bottom: 8px;
  font-size: 17px;
}

.mg-room figcaption .mg-room-rating {
  font-family: "Open Sans";
  background-color: #85c227;
  float: right;
  font-size: 13px;
  padding: 2px 8px;
  border-radius: 20px;
  margin-top: -18px;
  margin-right: -1px;
  z-index: 99;
  position: relative;
  -webkit-transform: translate(0, 120px);
  transform: translate(0, 120px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s;
  -webkit-transition-delay: 0.45s;
  transition-delay: 0.45s;
}

.mg-room figcaption .mg-room-price {
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
  -webkit-transform: translate(0, 120px);
  transform: translate(0, 120px);
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  transition: opacity 0.4s, transform 0.4s, -webkit-transform 0.4s;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.mg-room figcaption .btn-link {
  padding: 8px 0;
  opacity: 0;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s, color 0.3s;
  -webkit-transition: opacity 0.3s, color 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, color 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, color 0.3s;
  transition: opacity 0.3s, transform 0.3s, color 0.3s, -webkit-transform 0.3s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.mg-room figcaption .btn-link:hover,
.mg-room figcaption .btn-link:active,
.mg-room figcaption .btn-link:focus {
  color: #fff;
}

.mg-room figcaption .btn.btn-main {
  float: right;
  opacity: 0;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

.mg-room figcaption p,
.mg-room figcaption ul {
  opacity: 0;
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mg-room figcaption ul.styled-list {
  margin-bottom: 15px;
}

.mg-room figcaption ul.styled-list li {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.mg-room figcaption ul.styled-list li:before {
  color: #fff;
}

.mg-room.mg-room-col-4 figcaption h2 {
  -webkit-transform: translate(0, 75px);
  transform: translate(0, 75px);
}

.mg-room.mg-room-col-4 figcaption .mg-room-rating {
  -webkit-transform: translate(0, 75px);
  transform: translate(0, 75px);
}

.mg-room.mg-room-col-4 figcaption .mg-room-price {
  font-size: 20px;
  -webkit-transform: translate(0, 75px);
  transform: translate(0, 75px);
}

.mg-room.mg-room-col-4 figcaption .btn-link {
  margin-top: 20px;
}

.mg-room.mg-room-col-4 figcaption .btn.btn-main {
  margin-top: 20px;
}

.mg-room.mg-room-col-2 figcaption h2 {
  font-size: 25px;
  -webkit-transform: translate(0, 235px);
  transform: translate(0, 235px);
}

.mg-room.mg-room-col-2 figcaption .mg-room-rating {
  -webkit-transform: translate(0, 235px);
  transform: translate(0, 235px);
}

.mg-room.mg-room-col-2 figcaption .mg-room-price {
  -webkit-transform: translate(0, 235px);
  transform: translate(0, 235px);
}

.mg-room:hover figcaption {
  background-color: rgba(0, 0, 0, 0.2);
}

.mg-room:hover figcaption p,
.mg-room:hover figcaption ul,
.mg-room:hover figcaption .btn {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mg-room:hover figcaption .btn {
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mg-room:hover figcaption .btn.btn-link {
  -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
  -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
  transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
}

.mg-room:hover figcaption p,
.mg-room:hover figcaption ul {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.mg-room:hover figcaption h2,
.mg-room:hover figcaption .mg-room-rating,
.mg-room:hover figcaption .mg-room-price {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mg-room:hover figcaption h2,
.mg-room:hover figcaption .mg-room-rating {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.mg-room:hover figcaption .mg-room-price {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mg-room.mg-featured figcaption {
  padding: 20px 30px;
}

.mg-room.mg-featured figcaption h2 {
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 10px;
  -webkit-transform: translate(0, 310px);
  transform: translate(0, 310px);
}

.mg-room.mg-featured figcaption .mg-room-rating {
  font-size: 18px;
  line-height: 29px;
  font-weight: 300;
  margin-top: -27px;
  -webkit-transform: translate(0, 310px);
  transform: translate(0, 310px);
}

.mg-room.mg-featured figcaption .mg-room-price {
  font-family: "Open Sans";
  font-size: 30px;
  line-height: 41px;
  font-weight: 300;
  margin-bottom: 15px;
  -webkit-transform: translate(0, 310px);
  transform: translate(0, 310px);
}

.mg-room.mg-featured figcaption .btn-link {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
}

.mg-room.mg-featured figcaption .btn.btn-main {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
}

.mg-room.mg-featured:hover figcaption h2,
.mg-room.mg-featured:hover figcaption .mg-room-rating,
.mg-room.mg-featured:hover figcaption .mg-room-price {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mg-room.mg-featured:hover figcaption .btn {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mg-about {
  padding: 70px 0;
  background-color: #85c227;
  background-image: url(../../../assets/images/about-bg.png);
  background-position: center center;
  color: #fff;
}

.mg-about .mg-sec-left-title {
  color: #fff;
}

.mg-partners {
  padding: 70px 0;
  background-color: #ffffff;
  /*background-image: url(../../../assets/images/about-bg.png);
  background-position: center center;*/
/*  color: #fff;*/
}

.mg-other-services, .mg-marketplace, .mg-testimonial {
  padding: 70px 0 40px;
  background-color: #e9e9e9;
}

.mg-features, .mg-workzone {
  padding: 70px 0 30px;
  background-color: #fff;
}

.mg-feature {
  margin-bottom: 40px;
}

.mg-feature .mg-feature-icon-title {
  margin-bottom: 10px;
}

.mg-feature .mg-feature-icon-title:before,
.mg-feature .mg-feature-icon-title:after {
  content: " ";
  display: table;
}

.mg-feature .mg-feature-icon-title:after {
  clear: both;
}

.mg-feature .mg-feature-icon-title i {
  display: block;
  width: 50px;
  line-height: 50px;
  background-color: #16262e;
  text-align: center;
  font-size: 21px;
  color: #fff;
  border-radius: 50%;
  float: left;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.mg-feature .mg-feature-icon-title h3 {
  display: block;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  color: #16262e;
  font-weight: 400;
  margin-left: 60px;
  margin-top: 13px;
  margin-bottom: 14px;
  text-transform: uppercase;
}

.mg-feature p {
  margin-bottom: 0;
}

.mg-feature:hover .mg-feature-icon-title i {
  background-color: #85c227;
}

.mg-testi-partners {
  padding: 70px 0;
  background-color: #85c227;
  background-image: url(../../../assets/images/clients-bg.png);
  background-position: center center;
  color: #fff;
}

.mg-testi-partners .mg-sec-left-title {
  color: #fff;
}

.mg-light-testimonial {
  padding: 70px 0;
  background-color: #f7f7f7;
  background-image: url(../../../assets/images/clients-bg.png);
  background-position: center center;
}

.mg-light-testimonial .mg-testimonial-slider blockquote {
  text-align: center;
  background-color: transparent;
}

.mg-light-testimonial .mg-testimonial-slider blockquote p {
  font-size: 15px;
  line-height: 24px;
}

.mg-light-testimonial .mg-testimonial-slider blockquote footer {
  color: #85c227;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-style: italic;
}

.mg-light-testimonial .mg-testimonial-slider blockquote footer:before {
  content: '';
}

.mg-testimonial-slider blockquote {
  font-weight: 400;
  border-width: 0;
  background-color: transparent;
}

.mg-testimonial-slider blockquote p {
  font-size: 15px;
  line-height: 24px;
}

.mg-testimonial-slider blockquote footer {
  color: #85c227;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-style: italic;
}

.mg-testimonial-slider blockquote footer:before {
  content: '';
}

.owl-carousel {
  overflow: hidden;
}

.owl-carousel:hover .owl-controls .owl-buttons .owl-prev {
  margin-left: 0;
}

.owl-carousel:hover .owl-controls .owl-buttons .owl-next {
  margin-right: 0;
}

.owl-theme .owl-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 0;
}

.owl-theme .owl-controls .owl-buttons .owl-prev {
  float: left;
  margin-left: -50px;
  -webkit-transition: margin-left 0.3s, background-color 0.5s;
  transition: margin-left 0.3s, background-color 0.5s;
}

.owl-theme .owl-controls .owl-buttons .owl-next {
  float: right;
  margin-right: -50px;
  -webkit-transition: margin-right 0.3s, background-color 0.5s;
  transition: margin-right 0.3s, background-color 0.5s;
}

.owl-theme .owl-controls .owl-buttons div {
  margin: 1px;
  padding: 13px 13px;
  font-size: 21px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.owl-theme .owl-controls .owl-buttons div:hover {
  background-color: #85c227;
}

.owl-theme .owl-controls .owl-page span {
  background: rgba(0, 0, 0, 0.5);
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background-color: #85c227;
}

.mg-part-logos {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-news-gallery {
  padding: 70px 0;
  background-color: #fff;
}

.mg-recnt-posts {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mg-recnt-post {
  padding: 0 0 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
}

.mg-recnt-post .mg-rp-date {
  width: 70px;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 27px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #16262e;
  padding: 0 5px;
  position: relative;
  border-radius: 1px;
  float: left;
  margin-right: 20px;
  /* &:after{
              content: '';
              display: block;
              width: 100%;
              height: 1px;
              background-color: @dcolor;
              position: absolute;
              top: 37px;
              left: 0;
          } */
}

.mg-recnt-post .mg-rp-date .mg-rp-month {
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
}

.mg-recnt-post > h3 {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin-top: 0;
}

.mg-recnt-post > h3 > a {
  color: #16262e;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.mg-recnt-post > h3 > a:hover {
  color: #85c227;
  text-decoration: none;
}

.mg-recnt-post > p {
  margin-left: 90px;
  margin-bottom: 0;
}

.mg-gallery-container {
  position: relative;
}

.mg-gallery {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-gallery .owl-controls {
  margin-top: 0;
  position: absolute;
  top: 50%;
  width: 100%;
}

.mg-gallery .owl-controls .owl-buttons div {
  background-color: rgba(255, 255, 255, 0.4) !important;
  padding: 13px 15px;
  border-radius: 25px;
  margin-top: -25px;
  opacity: 0;
  color: #16262e;
  -webkit-transition: opacity 0.3s, background-color 0.3s, color 0.3s !important;
  transition: opacity 0.3s, background-color 0.3s, color 0.3s !important;
}

.mg-gallery .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: 25px !important;
}

.mg-gallery .owl-controls .owl-buttons div.owl-next {
  float: right;
  margin-right: 25px !important;
}

.mg-gallery .owl-controls .owl-buttons div:hover {
  opacity: 1;
  color: #fff;
  background-color: #85c227 !important;
}

.mg-gallery:hover .owl-controls .owl-buttons div {
  opacity: 0.5;
}

.mg-gallery-thumb {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 330px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -165px;
}

.mg-gallery-thumb .owl-item {
  opacity: 0.4;
}

.mg-gallery-thumb .owl-item.synced {
  opacity: 1;
}

.mg-part-logos-full {
  margin: 0;
  padding: 0;
}

.mg-part-logos-full li {
  list-style: none;
  text-align: center;
}

.mg-map {
  width: 100%;
  height: 300px;
}

.mg-contact-form-input, .form-input {
  margin-bottom: 20px;
  display: block;
}

.mg-contact-form-input label, .form-input label {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  color: #16262e;
  margin-bottom: 0px;
}

.mg-contact-info {
  margin: 0;
  padding: 0;
  list-style: none;
/*  margin-bottom: 50px;*/
}

.mg-contact-info li {
  margin-bottom: 20px;
}

.mg-contact-info li i {
  font-size: 18px;
  display: inline-block;
  width: 20px;
  margin-right: 15px;
  text-align: center;
  color: #16262e;
}

.mg-contact-info li a {
  font-weight: 600;
  color: #16262e;
}

.mg-contact-info li a:hover {
  color: #85c227;
}

.mg-rooms-cols {
  padding: 70px 0;
}

.mg-single-room {
  padding: 100px 0 0;
}

.mg-room-fecilities ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-room-fecilities ul li {
  color: #16262e;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
  font-weight: 300;
}

.mg-room-fecilities ul li i {
  margin-right: 10px;
}

.mg-single-room-price {
  position: relative;
}

.mg-single-room-price .mg-srp-inner {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  width: 100px;
  padding: 21px 0px 17px;
  text-align: center;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -60px;
  background-color: #831c6d;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  border-radius: 50%;
}

.mg-single-room-price .mg-srp-inner > span {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 300;
  position: relative;
}

.mg-single-room-price .mg-srp-inner > span:after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: #85c227;
  top: -1px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
}

.mg-single-room-txt {
  padding: 50px 0 47px;
}

.mg-single-room-review-sec {
  padding: 0 0 70px;
}

.mg-sm-full-rating {
  position: relative;
  margin-bottom: 50px;
  /* .mg-sec-left-title{
          &:before{
              content: '';
              display: block;
              position: absolute;
              bottom: 1px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #f2f2f2;
          }
      } */
}

.mg-sm-full-rating .mg-smfr-rate {
  color: #fff;
  background-color: #85c227;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 30px;
}

.mg-sm-full-rating .mg-smfr-cont {
  background-color: #fff;
  position: absolute;
  bottom: 0;
}

.mg-reviews {
  padding-right: 50px;
}

.mg-reviews .media {
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
}

.mg-reviews .media:last-child {
  border-bottom-width: 0;
}

.mg-reviews .media .media-body .media-heading {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
  font-style: italic;
  font-size: 22px;
  line-height: 40px;
}

.mg-reviews .media .media-body .mg-media-user-rating {
  display: inline-block;
}

.mg-reviews .media .media-body .mg-media-user-rating .mg-rs-icon .far {
  color: #ced4d7;
}

.mg-reviews .media .media-body .mg-media-user-rating .mg-rs-icon .fas.fa-star {
  color: #85c227;
  margin-right: 5px;
}

.mg-reviews .media .media-body p {
  font-style: italic;
}

.mg-reviews .media .media-body p:after {
  content: '”';
}

.mg-reviews .media .media-body p:before {
  content: '“';
}

.mg-reviews .media .media-body .media-date {
  float: right;
  color: #b3b3b3;
  font-size: 13px;
}

.mg-reviews .media .media-body .mg-media-rating {
  margin: 0 -15px;
  border-bottom: 1px solid #ebebeb;
  border: 1px solid #f2f2f2;
  padding: 5px 0;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector {
  width: 50%;
  float: left;
  padding: 5px 15px;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector .mg-rs-title {
  font-size: 18px;
  font-weight: 300;
  color: #16262e;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector .mg-rs-icon {
  float: right;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector .mg-rs-icon i {
  color: #b2bcc0;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector .mg-rs-icon i.fa-star {
  color: #85c227;
}

.mg-reviews .media .media-body .mg-media-rating .mg-rating-sector .mg-rs-icon i.fa-star-half-empty {
  color: #85c227;
}

.mg-reviews .media .media-left a {
  border-radius: 50%;
}

.mg-reviews .media .media-left a .media-object {
  border-radius: 50%;
}

.mg-reviews .mg-media-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mg-reviews .mg-media-meta .media-date {
  margin-left: auto;
}

.mg-reviews .mg-media-meta .mg-media-user-rating {
  margin-left: 20px;
  font-size: 12px;
}

.mg-single-room-bn.mg-book-now {
  margin-bottom: 70px;
  padding: 50px 50px 20px;
}

.mg-single-room-bn.mg-book-now .mg-sec-left-title {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.mg-single-room-bn.mg-book-now .mg-sec-left-title:after {
  left: 50%;
  margin-left: -40px;
}

.mg-star-rating-title {
  display: inline-block;
  font-size: 16px;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #16262e;
  min-width: 65px;
}

.mg-star-rating {
  margin-bottom: 20px;
}

.starrr {
  display: inline-block;
}

.starrr i {
  display: inline-block;
  padding: 3px;
  font-size: 14px;
  color: #ced4d7;
  cursor: pointer;
}

.starrr i.fas {
  color: #85c227;
}

.mg-rooms-no-padd > .container {
  padding-left: 30px;
  padding-right: 30px;
}

.mg-rooms-no-padd .row > .col-md-3,
.mg-rooms-no-padd .row > .col-md-4 {
  padding-left: 0;
  padding-right: 0;
}

.mg-rooms-no-padd .row > .col-md-3 > .mg-room,
.mg-rooms-no-padd .row > .col-md-4 > .mg-room {
  margin-bottom: 0;
}

.mg-gallery-page,
.mg-page {
  padding: 50px 0 100px;
}

#mg-grid {
  position: relative;
  overflow: visible !important;
  margin-top: 25px;
}

.mg-gallery-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mg-gallery-item:hover .mg-gallery-overlayer {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.mg-gallery-item:hover .mg-gallery-overlayer > i {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 1;
}

.mg-gallery-item .mg-gallery-overlayer {
  background-color: rgba(22, 38, 46, 0.9);
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: block;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.mg-gallery-item .mg-gallery-overlayer > i {
  width: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #85c227;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -25px;
  color: #16262e;
  font-size: 16px;
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.mg-gallery-item .mg-gallery-overlayer .album-name {
  line-height: 50px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  margin: 0 auto;
  /* margin-top: 5px;
  margin-left: -45px; */
  color: #ffffff;
}

.mg-gallery-item .mg-gallery-overlayer > i:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.mg-filter {
  text-align: center;
}

.mg-filter label > input {
  display: none;
}

.mg-blog-list {
  padding: 70px 0 100px;
}

.mg-post {
  padding-right: 15px;
  margin-bottom: 60px;
}

.mg-post .mg-post-title {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  margin-bottom: 15px;
  margin-top: 20px;
}

.mg-post .mg-post-title a {
  color: #85c227;
}

.mg-post .mg-post-title a:hover {
  color: #831c6d;
}

.mg-post .mg-post-meta {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
  margin-bottom: 20px;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #96a3a9;
}

.mg-post .mg-post-meta span {
  padding-right: 10px;
}

.mg-post .mg-post-meta span:after {
  content: '/';
  padding-left: 15px;
  color: #ced4d7;
}

.mg-post .mg-post-meta span:last-child:after {
  content: '';
  padding-left: 0;
}

.mg-post .mg-post-meta a {
  color: #264251;
}

.mg-post .mg-post-meta a:hover {
  color: #85c227;
}

.mg-post .mg-read-more {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-style: italic;
  font-size: 15px;
}

.mg-widget {
  background-color: #831c6d;
  padding: 30px;
  margin-bottom: 30px;
  color: #c0c8cb;
}

.mg-widget .mg-widget-title {
  color: #fff;
}

.mg-widget .form-control {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.mg-widget .form-control:focus {
  border-color: #85c227;
  background-color: transparent;
}

.mg-widget .mg-recnt-posts .mg-recnt-post {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.mg-widget .mg-recnt-posts .mg-recnt-post p {
  font-family: "Open Sans";
  margin-bottom: 0;
}

.mg-widget .mg-recnt-posts .mg-recnt-post .mg-rp-date {
  color: #fff;
}

.mg-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-widget ul li {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mg-widget ul li a {
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}

.mg-widget ul li a:hover {
  color: #85c227;
}

.mg-widget .tagcloud a {
  background-color: rgba(0, 0, 0, 0.1);
}

.mg-widget .tagcloud a:hover {
  background-color: #85c227;
}

.tagcloud a {
  display: inline-block;
  padding: 5px 10px;
  background-color: #16262e;
  color: #fff;
  border-radius: 1px;
  margin-right: 2px;
  margin-bottom: 6px;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.tagcloud a:hover {
  background-color: #85c227;
}

.mg-post-nav {
  margin-bottom: 70px;
  border-bottom: 1px solid #F2F2F2;
  border-top: 1px solid #F2F2F2;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #16262e;
}

.mg-post-nav a {
  padding: 10px;
  display: block;
  color: #adb9bf;
}

.mg-post-nav a:hover {
  color: #16262e;
}

.mg-post-nav > div {
  width: 50%;
}

.mg-post-nav div + div {
  text-align: right;
}

.mg-single-comments-list {
  margin-bottom: 70px;
}

.media .media-object {
  border-radius: 50%;
}

.media .media-body {
  position: relative;
  width: 100%;
}

.media .media-body .mg-comment-body {
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 20px;
}

.media .media-body .media-heading {
  font-size: 17px;
  line-height: 27px;
  color: #16262e;
}

.media .media-body .media-heading a {
  color: #16262e;
}

.media .media-body span {
  display: block;
  color: #adb9bf;
  margin-bottom: 10px;
}

.media .media-body span a {
  color: #adb9bf;
}

.media .media-body .btn-comment-reply {
  position: absolute;
  top: 0;
  right: 0;
}

.mg-about-features {
  padding: 70px 0 70px;
}

.mg-about-testimonial, .mg-about-core-value {
  padding: 70px 0;
  background-color: #85c227;
  background-image: url(../../../assets/images/about-bg.png);
  background-position: center center;
  color: #fff;
}

.mg-about-testimonial .mg-testimonial-slider blockquote {
  text-align: center;
  padding: 0 150px;
  padding-top: 35px;
  background-position: top center;
}

.mg-about-testimonial .mg-sec-title h2 {
  color: #fff;
}

.mg-team {
  padding: 70px 0 80px;
}

.mg-team-member {
  position: relative;
  margin-bottom: 30px;
}

.mg-team-member .mg-team-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.mg-team-member .mg-team-info h3 {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 8px;
  color: #fff;
}

.mg-team-member .mg-team-info h3:after {
  content: '';
  width: 0px;
  height: 2px;
  background-color: #85c227;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}

.mg-team-member .mg-team-info strong {
  display: block;
  font-style: italic;
  margin-bottom: 20px;
}

.mg-team-member .mg-team-member-overlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 38, 46, 0.9);
  opacity: 0;
  -webkit-transition: opacity 0.8s;
  transition: opacity 0.8s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mg-team-member .mg-team-member-social {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-team-member .mg-team-member-social li {
  display: inline-block;
  float: left;
}

.mg-team-member .mg-team-member-social li a {
  display: block;
  text-align: center;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-right: 5px;
  color: #fff;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.mg-team-member .mg-team-member-social li a:hover {
  background-color: #85c227;
}

.mg-team-member:hover .mg-team-member-overlayer {
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.mg-team-member:hover .mg-team-info {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mg-team-member:hover .mg-team-info h3:after {
  width: 60px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.mg-about-us-txt {
  text-align: center;
}

.mg-about-clients, .mg-about-vision {
  padding: 80px 0;
  background-color: #f2f2f2;
}

.mg-about-vmv {
  padding: 80px 0;
  background-color: #aad792;
}

.mg-booking-form .nav-tabs > li {
  width: 25%;
  text-align: center;
  font-size: 18px;
}

.mg-booking-form .nav-tabs > li > a {
  color: #ced4d6;
  border-width: 0;
  position: relative;
  background-color: transparent !important;
  border-width: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.mg-booking-form .nav-tabs > li > a span.mg-bs-tab-num {
  display: block;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #16262e;
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
  border-width: 3px;
  border-style: solid;
  border-color: #dbdedf;
  background-color: #fff;
  border-radius: 50%;
  padding: 0;
  text-transform: uppercase;
  width: 55px;
  line-height: 49px;
  margin-bottom: 10px;
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
}

.mg-booking-form .nav-tabs > li > a span.mg-bs-bar {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #dbdedf;
  position: absolute;
  top: 50%;
  right: -50%;
  margin-top: -17px;
  z-index: -1;
}

.mg-booking-form .nav-tabs > li > a span.mg-bs-bar:after {
  content: '';
  display: block;
  width: 0;
  height: 100%;
  background-color: #85c227;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: width 0.4s;
  transition: width 0.4s;
}

.mg-booking-form .nav-tabs > li > a.active,
.mg-booking-form .nav-tabs > li > a.active:hover,
.mg-booking-form .nav-tabs > li > a.active:focus,
.mg-booking-form .nav-tabs > li > a.active:active {
  border-width: 0;
  color: #16262e;
}

.mg-booking-form .nav-tabs > li > a.active span.mg-bs-tab-num {
  color: #85c227;
  border-color: #85c227;
}

.mg-booking-form .nav-tabs > li.mg-step-done > a {
  color: #85c227;
}

.mg-booking-form .nav-tabs > li.mg-step-done > a span.mg-bs-tab-num {
  color: #fff;
  border-color: #85c227;
  background-color: #85c227;
}

.mg-booking-form .nav-tabs > li.mg-step-done > a span.mg-bs-bar:after {
  width: 100%;
}

.mg-booking-form .tab-content {
  padding: 0;
  border-width: 0;
}

.mg-book-form-input {
  min-height: 100px;
}

.mg-book-form-input label {
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: #16262e;
  font-size: 16px;
  margin-bottom: 0px;
}

.mg-book-form-personal,
.mg-book-form-billing {
  padding-top: 50px;
  padding-bottom: 30px;
}

.mg-terms-input {
  margin-bottom: 30px;
}

.mg-terms-input label {
  font-weight: 400;
}

.mg-widget-cart-row {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mg-widget-cart-row strong {
  color: #fff;
  display: inline-block;
  margin-right: 5px;
}

.mg-cart-room h3 {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mg-cart-total {
  padding: 50px 0 10px;
  font-size: 25px;
  line-height: 35px;
/*  font-family: "Playfair Display", serif;*/
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-weight: 400;
}

.mg-cart-total strong {
  font-weight: 400;
}

.mg-cart-address {
  padding: 20px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mg-cart-address > strong {
  color: #fff;
  margin-bottom: 20px;
  display: block;
  float: left;
}

.mg-cart-address > address {
  margin-left: 120px;
}

.mg-saerch-room .mg-book-now {
  padding: 20px 30px;
}

.mg-saerch-room .mg-book-now .mg-bn-title {
  font-size: 20px;
}

.mg-saerch-room .mg-book-now .mg-bn-title .mg-bn-big {
  font-size: 16px;
}

.cs-select > span {
  padding-right: 1.5em;
}

.cs-select > span::after {
  right: 0.75em;
}

.mg-available-rooms {
  padding: 70px 0;
}

.mg-available-rooms .mg-avl-room {
  padding-bottom: 80px;
}

.mg-available-rooms .mg-avl-room:last-child {
  padding-bottom: 0;
}

.mg-available-rooms .mg-room-fecilities {
  margin-bottom: 15px;
}

.mg-available-rooms .mg-room-fecilities ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-available-rooms .mg-room-fecilities ul li {
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 5px;
}

.mg-available-rooms .mg-avl-room-title {
  margin-top: 0;
  margin-bottom: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.mg-available-rooms .mg-avl-room-title a {
  color: #16262e;
}

.mg-available-rooms .mg-avl-room-title span {
  font-family: "Open Sans";
  font-weight: 300;
  color: #85c227;
  float: right;
  /* 			position: relative;
  
  &:after{
      content: '';
      width: 100%;
      height: 3px;
      background-color: @mcolor;
      position: absolute;
      bottom: -12px;
      right: 0;
  } */
}

.mg-paid .mg-widget {
  background-image: url(../../../assets/images/paid.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
}

.mg-paid .mg-widget .mg-payment-id {
  color: #fff;
  margin: 0;
  font-size: 25px;
  margin-bottom: 20px;
}

.table > thead > tr > th {
  background-color: #f8f9f9;
  color: #16262e;
}

.card {
  border-radius: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #eaedee;
  margin-bottom: 20px;
  border-bottom: 0;
}

.card .collapse {
  border-bottom: 0;
}

.card .collapse.show {
  border-bottom: 1px solid #eaedee;
}

.card .collapsing {
  border-bottom: 1px solid #eaedee;
}

.card .card-header {
  border-color: #eaedee;
  background-color: #fff;
  padding: 0;
}

.card .card-header a {
  display: block;
  padding: 10px 15px;
  position: relative;
  color: #85c227;
  outline-width: 0;
}

.card .card-header a:after {
  content: '';
  width: 21px;
  height: 1px;
  background-color: #85c227;
  position: absolute;
  top: 50%;
  right: 20px;
}

.card .card-header a:before {
  content: '';
  width: 1px;
  height: 21px;
  background-color: #85c227;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: -10px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.5s ease 0.2s;
  transition: -webkit-transform 0.5s ease 0.2s;
  transition: transform 0.5s ease 0.2s;
  transition: transform 0.5s ease 0.2s, -webkit-transform 0.5s ease 0.2s;
}

.card .card-header a.collapsed {
  color: #16262e;
}

.card .card-header a.collapsed:before {
  background-color: #16262e;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.card .card-header a.collapsed:after {
  background-color: #16262e;
}

.card .card-header .panel-title {
  font-size: 19px;
  margin-bottom: 0;
}

.card .card-header + .panel-collapse > .panel-body {
  border-top-color: #eaedee;
}

.mg-footer-widget {
  background-color: #304213;
  padding: 70px 0 20px;
  color: #c0c8cb;
}

.mg-footer-widget .widget {
  margin-bottom: 50px;
}

.mg-footer-widget .widget:before,
.mg-footer-widget .widget:after {
  content: " ";
  display: table;
}

.mg-footer-widget .widget:after {
  clear: both;
}

.mg-footer-widget a {
  color: #fff;
}

.mg-footer-widget a:hover {
  color: #85c227;
}

.mg-footer-widget .form-control {
  height: auto;
  padding: 8px 12px;
  border-radius: 1px;
  border-color: #fff;
  color: #fff;
  background-color: transparent;
}

.mg-footer-widget .form-control::-webkit-input-placeholder {
  color: #fff;
}

.mg-footer-widget .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.mg-footer-widget .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.mg-footer-widget .form-control:-ms-input-placeholder {
  color: #fff;
}

.mg-footer-widget .form-control:focus {
  color: #fff;
  border-color: #85c227;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mg-footer-widget .mg-instagram a {
  display: block;
  position: relative;
}

.mg-footer-widget .mg-instagram a:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.mg-footer-widget .mg-instagram a:before {
  font-family: 'Font Awesome 5 Brands';
  content: '\f16d';
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  color: #fff;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.mg-footer-widget .mg-instagram a:hover:after {
  background-color: rgba(22, 38, 46, 0.7);
}

.mg-footer-widget .mg-instagram a:hover:before {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  opacity: 1;
}

.mg-footer-widget .mg-instagram a img {
  width: 100%;
}

.mg-footer-widget .mg-widget-title {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 25px;
}

.mg-footer-widget .mg-widget-title:after {
  width: 50px;
  background-color: #85c227;
}

.mg-footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-footer-social > li {
  display: inline-block;
  margin-right: 3px;
}

.mg-footer-social > li > a {
  display: block;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.15);
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.mg-footer-social > li > a:hover {
  background-color: #85c227;
  color: #fff;
}

.mg-instagram {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-instagram > li {
  float: left;
  width: 33.33333333%;
}

.mg-copyright {
  padding: 15px 0;
  color: #7a8a92;
  background-color: #212e0c;
  font-size: 13px;
  text-align: right;
}

.mg-copyright a {
  color: #96a3a9;
}

.mg-copyright a:hover {
  color: #85c227;
  text-decoration: none;
}

.mg-copyright p {
  margin: 0;
}

.mg-footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mg-footer-nav > li {
  float: left;
  margin-right: 20px;
}

.mg-light-footer .mg-footer-widget {
  background-color: #f7f7f7;
  color: #7a8a92;
}

.mg-light-footer .mg-widget-title {
  color: #16262e;
}

.mg-light-footer .mg-copyright {
  color: #7a8a92;
  background-color: #eaeaea;
}

.mg-light-footer .mg-footer-social > li > a {
  background-color: #16262e;
}

.mg-light-footer .mg-footer-social > li > a:hover {
  background-color: #85c227;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 1199px) {
  .carousel-caption > h2, .mg-page-title h2 {
    font-size: 40px;
  }
  .carousel-caption > p {
    font-size: 18px;
  }
  .hide-on-mobile {
    display: block;
  }
  .hide-on-desktop {
    display: none;
  }
  .mg-room figcaption h2 {
    -webkit-transform: translate(0, 90px);
    transform: translate(0, 90px);
  }
  .mg-room figcaption .mg-room-rating {
    -webkit-transform: translate(0, 90px);
    transform: translate(0, 90px);
  }
  .mg-room figcaption .mg-room-price {
    -webkit-transform: translate(0, 90px);
    transform: translate(0, 90px);
  }
  .mg-room figcaption .btn-link {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room figcaption p {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 20px;
  }
  .mg-room figcaption p,
  .mg-room figcaption ul {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room.mg-room-col-2 figcaption h2 {
    font-size: 25px;
    -webkit-transform: translate(0, 180px);
    transform: translate(0, 180px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-rating {
    -webkit-transform: translate(0, 180px);
    transform: translate(0, 180px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-price {
    -webkit-transform: translate(0, 180px);
    transform: translate(0, 180px);
  }
  .mg-room.mg-room-col-2 figcaption p {
    display: none;
  }
  .mg-room.mg-room-col-2:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul,
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-room.mg-room-col-4 figcaption h2 {
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-rating {
    display: none;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-price {
    font-size: 20px;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .btn-link {
    margin-top: 0;
  }
  .mg-room.mg-room-col-4 figcaption .btn.btn-main {
    display: none;
    margin-top: 20px;
  }
  .mg-room.mg-room-col-4:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul,
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-book-now .mg-bn-title {
    font-size: 22px;
  }
  .mg-book-now .mg-bn-title .mg-bn-big {
    font-size: 14px;
  }
  .mg-404-error-txt {
    padding: 0px 140px;
  }
}

@media (max-width: 991px) {
  .mg-page-title {
    padding-top: 50px;
  }
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 38px;
  }
  .carousel-caption > p {
    font-size: 18px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
  .navbar-nav > li > a {
    padding: 28px 9px 27px;
  }
  .navbar-brand img {
    height: 55px;
  }
  .mg-search-box-cont {
    margin: 15px 20px;
  }
  .mg-search-box {
    bottom: -73px;
  }
  .mg-room figcaption h2 {
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room figcaption .mg-room-rating {
    display: none;
    -webkit-transform: translate(0, 90px);
    transform: translate(0, 90px);
  }
  .mg-room figcaption .mg-room-price {
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room figcaption .btn-link {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room figcaption .btn-main {
    display: none;
  }
  .mg-room figcaption p {
    display: none;
  }
  .mg-room figcaption p,
  .mg-room figcaption ul {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room.mg-room-col-2 figcaption h2 {
    font-size: 25px;
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-rating {
    display: block;
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-price {
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-2 figcaption .btn-main {
    display: inline-block;
  }
  .mg-room.mg-room-col-2 figcaption ul {
    display: none;
  }
  .mg-room.mg-room-col-2 figcaption p {
    display: block;
  }
  .mg-room.mg-room-col-2:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul,
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-room.mg-room-col-4 figcaption h2 {
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-rating {
    display: block;
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-price {
    font-size: 20px;
    -webkit-transform: translate(0, 120px);
    transform: translate(0, 120px);
  }
  .mg-room.mg-room-col-4 figcaption .btn-link {
    margin-top: 60px;
  }
  .mg-room.mg-room-col-4 figcaption .btn.btn-main {
    display: inline-block;
    margin-top: 60px;
  }
  .mg-room.mg-room-col-4:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul,
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-404-error-txt {
    padding: 0px 40px;
  }
  .mg-about-testimonial .mg-testimonial-slider blockquote {
    padding: 35px 80px 0px;
  }
  .mg-team-member .mg-team-info p {
    display: none;
  }
  .mg-book-now .mg-bn-forms .col-lg-3,
  .mg-book-now .mg-bn-forms .col-md-6,
  .mg-book-now .mg-bn-forms .col-6 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mg-book-now .mg-bn-forms .col-md-6 {
    margin-bottom: 30px;
  }
  .mg-book-now .mg-bn-forms > form > .row:first-child .row {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 30px;
  }
  .mg-book-now .mg-bn-title {
    text-align: center;
    -webkit-box-shadow: none;
            box-shadow: none;
    position: relative;
  }
  .mg-book-now .mg-bn-title:after {
    content: '';
    width: 100px;
    height: 3px;
    background-color: #85c227;
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -50px;
  }
  .mg-instagram > li {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .container {
    width: 480px;
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 32px;
  }
  .carousel-caption > p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
  .header .container {
    position: relative;
  }
  .header .navbar-collapse {
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 100%;
    right: 15px;
    min-width: 300px;
    padding: 0;
    text-align: left;
    z-index: 99;
  }
  .header .navbar-collapse .navbar-nav {
    margin: 0;
  }
  .navbar-nav .open .dropdown-menu > .active > a {
    color: #fff !important;
    background-color: #85c227 !important;
  }
  .navbar-nav > li > a {
    padding: 15px 20px !important;
  }
  .navbar-nav > li > a:hover {
    color: #fff !important;
    background-color: #85c227 !important;
  }
  .dropdown > ul {
    display: none;
    padding-left: 20px !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .dropdown > ul a {
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
  }
  .dropdown > ul a:hover {
    color: #85c227 !important;
  }
  .navbar-inverse .navbar-nav > li.active > a {
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #fff !important;
    background-color: #85c227 !important;
  }
  .header .navbar-collapse.collapse {
    display: none !important;
  }
  .header .navbar-collapse.collapse.show {
    display: block !important;
  }
  .mg-search-box-cont {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .mg-room figcaption h2 {
    -webkit-transform: translate(0, 170px);
    transform: translate(0, 170px);
  }
  .mg-room figcaption .mg-room-rating {
    display: block;
    -webkit-transform: translate(0, 170px);
    transform: translate(0, 170px);
  }
  .mg-room figcaption .mg-room-price {
    -webkit-transform: translate(0, 170px);
    transform: translate(0, 170px);
  }
  .mg-room figcaption .btn-link {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room figcaption .btn-main {
    display: inline-block;
  }
  .mg-room figcaption p {
    display: block;
    margin-bottom: 23px;
    font-size: inherit;
    line-height: inherit;
  }
  .mg-room figcaption p,
  .mg-room figcaption ul {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  .mg-room.mg-room-col-2 figcaption h2 {
    font-size: 25px;
    -webkit-transform: translate(0, 175px);
    transform: translate(0, 175px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-rating {
    display: block;
    -webkit-transform: translate(0, 175px);
    transform: translate(0, 175px);
  }
  .mg-room.mg-room-col-2 figcaption .mg-room-price {
    -webkit-transform: translate(0, 175px);
    transform: translate(0, 175px);
  }
  .mg-room.mg-room-col-2 figcaption .btn-main {
    display: inline-block;
  }
  .mg-room.mg-room-col-2 figcaption ul {
    display: none;
  }
  .mg-room.mg-room-col-2 figcaption p {
    display: block;
  }
  .mg-room.mg-room-col-2:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul,
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-2:hover figcaption p,
  .mg-room.mg-room-col-2:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-2:hover figcaption h2,
  .mg-room.mg-room-col-2:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-2:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-room.mg-room-col-4 figcaption h2 {
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-rating {
    display: none;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .mg-room-price {
    font-size: 20px;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
  }
  .mg-room.mg-room-col-4 figcaption .btn-link {
    margin-top: 0;
  }
  .mg-room.mg-room-col-4 figcaption .btn.btn-main {
    display: none;
    margin-top: 60px;
  }
  .mg-room.mg-room-col-4:hover figcaption {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul,
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption .btn {
    opacity: 1;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption .btn.btn-link {
    -webkit-transition: opacity 0.3s ease 0.5s, -webkit-transform 0.3s ease 0.5s, color 0.3s;
    -webkit-transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s;
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s, color 0.3s, -webkit-transform 0.3s ease 0.5s;
  }
  .mg-room.mg-room-col-4:hover figcaption p,
  .mg-room.mg-room-col-4:hover figcaption ul {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .mg-room.mg-room-col-4:hover figcaption h2,
  .mg-room.mg-room-col-4:hover figcaption .mg-room-rating {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .mg-room.mg-room-col-4:hover figcaption .mg-room-price {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .mg-404-error-txt {
    padding: 0px;
  }
  .mg-404-badg {
    width: 190px;
    margin-bottom: 20px;
    float: none !important;
  }
  .mg-about-testimonial .mg-testimonial-slider blockquote {
    padding: 35px 0 0px;
  }
  .mg-team-member .mg-team-info p {
    display: block;
  }
  .mg-booking-form .nav-tabs > li > a span.mg-bs-bar {
    margin-top: -15px;
  }
  .mg-booking-form .nav-tabs > li {
    font-size: 13px;
  }
  .mg-booking-form .nav-tabs > li > a {
    padding-right: 5px;
    padding-left: 5px;
  }
  .mg-available-rooms img {
    margin-bottom: 20px;
  }
  .mg-booking-form .nav-tabs > li > a span.mg-bs-tab-num {
    width: 45px;
    line-height: 39px;
  }
  .mg-instagram > li {
    width: 20%;
  }
}

@media (max-width: 662px) {
  .header.transp + .carousel .carousel-caption {
    margin-bottom: 0;
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 28px;
  }
  .carousel-caption > p {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
  .carousel-caption > img {
    width: 100px;
  }
  .header.transp + .carousel .carousel-control {
    margin-top: -60px !important;
  }
  .header.transp {
    position: relative;
    top: 0;
    z-index: 999;
  }
  .header.transp .navbar-inverse,
  .header.transp .mg-search-box {
    background-color: #16262e;
  }
  .header.transp .navbar-default {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .header.transp + .mg-page-title {
    padding-top: 50px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 28px;
  }
  .carousel-caption > p {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  .mg-404-error-txt .mg-404-txt-search .mg-404-search-form input {
    max-width: 150px;
  }
}

/*
   * Boxed Mode Responsive 
   */
@media (max-width: 1199px) {
  .mg-boxed {
    width: 992px;
  }
  .mg-boxed .header {
    max-width: 992px;
  }
  .mg-boxed .carousel-caption > h2 {
    font-size: 42px;
  }
  .mg-boxed .carousel-caption > p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 991px) {
  .mg-boxed {
    width: 767px;
  }
  .mg-boxed .header {
    max-width: 767px;
  }
  .mg-boxed .carousel-caption > h2 {
    font-size: 35px;
  }
  .mg-boxed .carousel-caption > p {
    font-size: 15px;
    line-height: 25px;
  }
  .mg-boxed .carousel-caption > img {
    width: 120px;
  }
}

@media (max-width: 767px) {
  .mg-boxed {
    width: 500px;
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .mg-boxed .header {
    max-width: 500px;
  }
  .mg-boxed .carousel-caption > h2 {
    font-size: 25px;
  }
  .mg-boxed .carousel-caption > p {
    font-size: 13px;
    line-height: 23px;
  }
  .mg-boxed .carousel-caption > img {
    width: 90px;
  }
  .mg-boxed .header.transp + .carousel .carousel-caption {
    margin-bottom: 0;
  }
  .mg-boxed .header.transp + .carousel .carousel-control {
    margin-top: -60px !important;
  }
  .mg-boxed .header.transp {
    position: relative;
    top: 0;
    z-index: 999;
  }
  .mg-boxed .header.transp .navbar-inverse,
  .mg-boxed .header.transp .mg-search-box {
    background-color: #16262e;
  }
  .mg-boxed .header.transp .navbar-default {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .mg-boxed .header.transp + .mg-page-title {
    padding-top: 50px;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
    bottom: 60%;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
}

@media (max-width: 500px) {
  .mg-boxed {
    width: 100%;
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .mg-boxed .header {
    max-width: 100%;
  }
  .navbar > .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .carousel-caption {
    right: 2%;
    left: 2%;
    bottom: 60%;
  } 
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 28px;
  }
  .carousel-caption > p {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
}

@media (max-width: 400px) {
  .navbar-brand img {
/*    height: 40px;*/
  }
  .mg-page-title {
    padding-top: 50px;
  }
  .navbar > .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .carousel-caption {
    right: 1%;
    left: 1%;
    bottom: 58%;
  } 
  .carousel-caption > h2, .mg-page-title h2  {
    font-size: 18px;
  }
  .carousel-caption > p {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  .hide-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
}
